<template>
  <div>
    <LabelAction
      v-if="editable"
      :label="$translations.profile.approaches"
      :action="$translations.general.edit"
      @clicked="showForm = true"
    />

    <div class="approaches">
      <Chip
        v-for="(item, index) in approachesData"
        :key="index"
        :text="item.name"
        color="gray"
        size="medium" />
    </div>

    <ApproachesFormModal
      :specialistApproaches="approachesData"
      @changed="updateApproaches"
      @close="showForm = false"
      v-if="showForm" />
  </div>
</template>

<script>
import { Chip } from '@seliaco/red-panda'
import LabelAction from '@/components/LabelAction'
import ApproachesFormModal from '@/views/profile/components/general/modals/ApproachesFormModal'

export default {
  name: 'Approaches',
  components: { ApproachesFormModal, LabelAction, Chip },
  props: {
    approaches: Array,
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showForm: false,
      approachesData: []
    }
  },
  mounted () {
    this.approachesData = this.approaches.map((o) => {
      return {
        name: o.approach.name,
        code: o.approach.id
      }
    })
  },
  methods: {
    updateApproaches (values) {
      this.approachesData = values
      this.showForm = false
    }
  }
}
</script>

<style lang="sass" scoped>
.approaches
  display: flex
  flex-wrap: wrap
  gap: 8px
</style>
