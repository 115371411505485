<template>
  <div class="label-action margin-y">
    <div class="heading-small" v-text="label" />
    <div class="content-title text-purple cursor-pointer" @click="$emit('clicked')" v-text="action" />
  </div>
</template>

<script>
export default {
  name: 'LabelAction',
  props: {
    label: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
.label-action
  display: flex
  justify-content: space-between
  gap: 8px
</style>
