<template>
  <div class="relative">
    <!-- backdrop -->
    <div v-on:click="$emit('on-nav-back')" class="backdrop" />

    <div class="modal-container">
      <!-- navbar -->
      <div class="nav-bar padding-all">
        <!-- arrow back -->
        <div class="cursor-pointer" v-on:click="$emit('on-nav-back')">
          <div class="icon-arrow-big-outline-left icon-display" />
        </div>

        <!-- title -->
        <div class="title content-title" v-text="title" />
        <div class="right-action">
          <div
            class="icon-display cursor-pointer"
            :class="rightAction.icon"
            v-if="rightAction"
            v-on:click="$emit('right-action-clicked')"
          />
        </div>
      </div>

      <!-- content -->
      <div class="modal-content" :class="{ 'modal-content-no-padding': !noPadding }">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalContainer',
  props: {
    title: String,
    transparentBackground: {
      type: Boolean,
      default: false
    },
    noPadding: Boolean,
    rightAction: {
      icon: String
    }
  }
}
</script>

<style lang="sass">
.modal-container
  border-radius: 20px 20px 0 0
  height: calc(100vh - 128px)
  max-width: 600px
  margin-top: 128px
  margin-right: auto
  margin-left: auto
  z-index: 60
  background-color: white
  position: fixed
  top: 0
  left: 0
  right: 0
  .nav-bar
    display: flex
    border-bottom: 1px solid var(--gray-50)
  .title
    flex-grow: 1
    text-align: center
    align-self: center
    overflow: hidden
    text-overflow: ellipsis
  .right-action
    text-align: center
    align-self: center
.modal-content
  height: 100%
  overflow: scroll
  width: 100%
  &-no-padding
    padding: 16px 16px 32px
.backdrop
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 50
  background-color: var(--black)
  opacity: 0.6
</style>
