<template>
  <div>
    <PageContainer
      :title="$translations.profile['therapy-style'].title"
      :back="actions.close"
      :has-shy-header="true"
    >
      <template v-slot:content>
        <template v-if="loading">
          <div class="page-segment">
            <div class="page-segment-content">
              <Skeleton
                class="margin-y"
                width="100%"
                height="20px"
                borderRadius="16px"
              />
              <Skeleton
                class="margin-bottom"
                width="100%"
                height="72px"
                borderRadius="16px"
              />
              <Skeleton
                class="margin-bottom"
                width="100%"
                height="100px"
                borderRadius="16px"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <!-- my therapy style -->
          <div class="page-segment">
            <div class="page-segment-content">
              <LabelAction
                :label="$translations.profile['therapy-style'].sections['therapy-style-title']"
                :action="labelActionText(editConsultationDescription)"
                @clicked="updateMeta('CONSULTATION_DESCRIPTION')"
              />
              <div class="body" v-text="$translations.profile['therapy-style'].sections['therapy-style-description']" />

              <!-- approaches -->
              <div class="margin-top body-small" v-text="$translations.profile['therapy-style'].approaches" />
              <Approaches :editable="false" :approaches="specialistData.approaches" />
              <TextareaField
                class="margin-top"
                v-model="form.consultation_style_description"
                :label="$translations.profile['textarea-placeholder']"
                :value="form.consultation_style_description"
                :disabled="!editConsultationDescription"
                :max-length="350"
                @change="setForm('consultation_style_description', $event)"
              />
            </div>
          </div>

          <!-- population -->
          <div class="page-segment">
            <div class="page-segment-content">
              <LabelAction
                :label="$translations.profile['therapy-style'].sections['populations-title']"
                :action="labelActionText(showPopulationModal)"
                @clicked="showPopulationModal = true"
              />
              <div class="body" v-text="$translations.profile['therapy-style'].sections['populations-description']" />
              <div v-if="specialistData && specialistData.population.length">
                <div class="population-chips">
                  <Chip
                    v-for="(item, index) in specialistData.population"
                    :key="index"
                    size="small"
                    color="purple"
                    :text="item.name"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- consultation styles -->
          <div class="page-segment">
            <div class="page-segment-content">
              <LabelAction
                :label="$translations.profile['therapy-style'].sections['consultation-style-title']"
                :action="labelActionText(showModal)"
                @clicked="showModal = true"
              />
              <div class="body" v-text="$translations.profile['therapy-style'].sections['consultation-style-description']" />
              <div class="chips">
                <Chip
                  v-for="(item, index) in specialistData.consultation_style" :key="index"
                  size="pill-field"
                  color="white-outline"
                  icon-position="left"
                  :emoji="item.icon_code"
                  :text="item.name" />
              </div>
            </div>
          </div>

          <!-- first session -->
          <div class="page-segment">
            <div class="page-segment-content">
              <LabelAction
                :label="$translations.profile['therapy-style'].sections['first-session-title']"
                :action="labelActionText(editFirstSessionDescription)"
                @clicked="updateMeta('FIRST_SESSION')"
              />
              <div
                class="body"
                v-text="$translations.profile['therapy-style'].sections['first-session-description']"
              />
              <TextareaField
                class="margin-top"
                v-model="form.first_session_description"
                :label="$translations.profile['textarea-placeholder']"
                :value="form.first_session_description"
                :disabled="!editFirstSessionDescription"
                :max-length="350"
                @change="setForm('first_session_description', $event)"
              />
            </div>
          </div>
        </template>
      </template>
    </PageContainer>
    <ConsultationStylesFormModal
      @close="setConsultationStyles"
      v-if="showModal"
      :options="options"
      :values="specialistData.consultation_style"
    />
    <PopulationFormModal
      @close="setPopulation"
      v-if="showPopulationModal"
      :options="populationOptions"
      :values="specialistData.population"
    />
  </div>

</template>

<script>
import {
  PageContainer,
  Specialists,
  Skeleton,
  Chip
} from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'
import LabelAction from '@/components/LabelAction'
import Approaches from '@/views/profile/components/general/Approaches'
import ConsultationStylesFormModal from '@/views/profile/components/general/modals/ConsultationStylesFormModal'
import PopulationFormModal from '@/views/profile/components/general/modals/PopulationFormModal'

export default {
  name: 'TherapyStyleForm',
  components: {
    PageContainer,
    LabelAction,
    TextareaField,
    Skeleton,
    Approaches,
    Chip,
    ConsultationStylesFormModal,
    PopulationFormModal
  },
  data () {
    return {
      loading: true,
      options: [],
      populationOptions: [],
      showModal: false,
      showPopulationModal: false,
      form: {
        consultation_style_description: null,
        first_session_description: null
      },
      specialistData: null,
      editConsultationDescription: false,
      editFirstSessionDescription: false,
      actions: {
        close: {
          callback: () => this.$router.push({ name: 'Profile' })
        },
        submit: {
          text: this.$translations.general.save,
          callback: () => this.update(),
          loading: false
        }
      }
    }
  },
  created () {
    this.getTherapyStyle(true)
    this.getConsultationStyleOptions()
    this.getPopulationOptions()
  },
  methods: {
    getConsultationStyleOptions () {
      Specialists.listConsultationStyles(this.$store.getters['auth/specialist'].id)
        .then((response) => {
          this.options = response
        })
    },
    getPopulationOptions () {
      Specialists.listPopulationOptions(this.$store.getters['auth/specialist'].id)
        .then((response) => {
          this.populationOptions = response
        })
    },
    getTherapyStyle (loading) {
      this.loading = loading
      Specialists.getSpeTherapyStyle(
        this.$store.getters['auth/specialist'].id
      ).then((response) => {
        this.specialistData = response
        this.form.consultation_style_description = this.specialistData.consultation_style_description
        this.form.first_session_description = this.specialistData.first_session_description
        this.loading = false
      })
    },
    setForm (field, value) {
      this.form[field] = value
    },
    setConsultationStyles (values) {
      if (values) {
        Specialists.updateSpeConsultationStyle(this.$store.getters['auth/specialist'].id, values)
          .then(() => {
            this.$toast({
              severity: 'success',
              text: this.$translations.success.updated
            })
          })
          .finally(() => {
            this.getTherapyStyle(false)
            this.showModal = false
          })
      } else {
        this.showModal = false
      }
    },
    setPopulation (values) {
      if (values) {
        Specialists.updateSpecialistPopulation(this.$store.getters['auth/specialist'].id, values)
          .then(() => {
            this.$toast({
              severity: 'success',
              text: this.$translations.success.updated
            })
          })
          .finally(() => {
            this.getTherapyStyle(false)
            this.showPopulationModal = false
          })
      } else {
        this.showPopulationModal = false
      }
    },
    updateMeta (value) {
      if (this.editConsultationDescription || this.editFirstSessionDescription) {
        Specialists.updateSpecialistMeta(
          this.$store.getters['auth/specialist'].id,
          this.form
        )
          .then(() => {
            this.$toast({
              severity: 'success',
              text: this.$translations.success.updated
            })
          })
          .finally(() => {
            this.editConsultationDescription = this.editFirstSessionDescription = false
          })
      } else {
        value === 'CONSULTATION_DESCRIPTION'
          ? this.editConsultationDescription = true
          : this.editFirstSessionDescription = true
      }
    }
  },
  computed: {
    labelActionText () {
      return (value) => {
        return value
          ? this.$translations.general.save
          : this.$translations.general.edit
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.chips
  padding: 16px 0
  display: flex
  gap: 4px
  flex-wrap: wrap
  align-items: flex-end
.population-chips
  margin-top: 10px
  display: flex
  gap: 4px
  flex-wrap: wrap
  ::v-deep .body-small
    overflow: unset
    text-overflow: unset
</style>
