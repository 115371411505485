<template>
  <ModalContainer
    @on-nav-back="$emit('close')"
    @right-action-clicked="submit"
    :rightAction="{ icon: 'icon-check-single' }"
    :title="$translations.profile.approaches">
    <template v-slot:content>
      <!-- Selected approaches -->
      <div class="heading-small margin-bottom">
        {{ $translations.profile['my-approaches'] }}
      </div>

      <div class="flex flex-row flex-wrap gap-1" v-if="specialistApproachesData && specialistApproachesData.length > 0">
        <div class="flex gap-1 padding-y-xs padding-left  padding-right-xs  bg-purple-10 rounded-2xl" v-for="(item, index) in specialistApproachesData" :key="index">
          <div class="flex-grow self-center content-title">{{ item.name }}</div>
          <div class="flex-none self-center heading icon-close cursor-pointer" @click="remove(item)"></div>
        </div>
      </div>

      <div class="body-small text-purple text-center" v-else>
        {{ $translations.form['select-one-more-options'] }}
      </div>

      <hr class="margin-y border-gray-10" />

      <!-- approach selection -->
      <MultiselectCheckbox
        :loading="loading"
        :options="approaches"
        :value="specialistApproachesData"
      />
    </template>
  </ModalContainer>
</template>

<script>
import { SpecialistsApproaches, Approaches } from '@seliaco/red-panda'
import ModalContainer from '@/components/modals/ModalContainer'
import MultiselectCheckbox from '@/components/fields/MultiselectCheckbox'

export default {
  name: 'ApproachesFormModal',
  components: { MultiselectCheckbox, ModalContainer },
  props: {
    specialistApproaches: []
  },
  data () {
    return {
      loading: false,
      approaches: [],
      specialistApproachesData: this.specialistApproaches
    }
  },
  mounted () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      this.loading = true

      Approaches.get().then((response) => {
        this.approaches = response.map((option) => {
          return {
            code: option.id,
            name: option.name
          }
        })

        this.loading = false
      })
    },
    remove (option) {
      const index = this.specialistApproachesData.findIndex(o => o.code === option.code)

      this.specialistApproachesData.splice(index, 1)
    },
    submit () {
      this.$emit('changed', this.specialistApproachesData)
      SpecialistsApproaches.update(
        { approaches: this.specialistApproachesData.map(item => item.code) },
        this.$store.getters['auth/specialist'].id
      ).then(() => {
        this.$toast({
          severity: 'success',
          text: this.$translations.success.updated
        })
      }).catch(() => {
        this.$toast({
          severity: 'error',
          text: this.$translations.error.default
        })
      })
    }
  }
}
</script>
