<template>
  <div class="grid gap-1.25">
    <template v-if="loading">
      <div v-for="key in 6" :key="key">
        <Skeleton width="100%" height="57px" borderRadius="8px" />
      </div>
    </template>

    <template v-else>
      <div
        v-for="(option, key) in options"
        :key="key"
        :class="{ 'multiselect-box': isSelected(option.code) }"
        class="flex gap-1.25 padding-all rounded-xl border border-purple-20 cursor-pointer"
        @click="onSelect(option)"
      >
        <div class="multiselect-box-checkbox rounded-md self-center text-white padding-all-zero border-2 border-purple-60">
          <div class="icon hidden icon-check-single"></div>
        </div>

        <div>
          {{ option.name }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'

export default {
  name: 'MultiselectCheckbox',
  components: {
    Skeleton
  },
  props: {
    options: Array,
    value: Array,
    loading: Boolean
  },
  data () {
    return {
      valueData: this.value
    }
  },
  watch: {
    value (val) {
      this.valueData = val
    }
  },
  methods: {
    isSelected (code) {
      const index = this.valueData.findIndex(o => o.code === code)

      return index !== -1
    },
    onSelect (option) {
      const index = this.valueData.findIndex(o => o.code === option.code)

      if (index === -1) {
        this.valueData.push(option)
      } else {
        this.valueData.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="sass">
.multiselect-box-checkbox
  width: 24px
  height: 24px

.multiselect-box
  @apply bg-purple-10 border-purple-60

  .multiselect-box-checkbox
    @apply bg-purple border-purple

  .icon
    display: block!important
</style>
