<template>
  <ModalContainer
    @on-nav-back="close"
    @right-action-clicked="$emit('right-click')"
    :rightAction="{ icon: 'icon-check-single' }"
    :title="$translations.profile['my-consultation-styles']">
    <template v-slot:content>
      <div class="heading-small margin-bottom" v-text="$translations.profile['my-consultation-styles']" />
      <div class="chips">
        <Chip
          v-for="(item, index) in localValues" :key="index"
          size="pill-field"
          color="white-outline"
          icon-position="left"
          :emoji="item.icon_code || item.context.icon_code"
          :text="item.name || item.context.name" />
      </div>

      <div class="heading-small margin-y" v-text="$translations.profile['consultation-styles']" />
      <MultiselectField
        v-if="options.length"
        :items-selected="optionsSelected"
        :options="optionsMapped"
        :max-value="3"
        @value="addValues($event)"
      />
    </template>
  </ModalContainer>
</template>

<script>
import { MultiselectField, Chip } from '@seliaco/red-panda'
import ModalContainer from '@/components/modals/ModalContainer'

export default {
  name: 'ConsultationStylesFormModal',
  components: {
    ModalContainer,
    MultiselectField,
    Chip
  },
  props: {
    values: [],
    options: []
  },
  mounted () {
    this.localValues = this.values.map(option => {
      return {
        id: option.id,
        name: option.name,
        value: option.id,
        icon_code: option.icon_code
      }
    })
  },
  data () {
    return {
      loading: false,
      localValues: null,
      newValues: null
    }
  },
  methods: {
    addValues (values) {
      this.newValues = values
      /*
      * The local values are read only
      */
      this.localValues = this.newValues.map(option => {
        const val = option.context ? option.context : option
        return {
          id: val.id,
          name: val.name || val.text,
          value: val.id,
          icon_code: val.icon_code
        }
      })
    },
    close () {
      this.$emit('close', this.newValues)
    }
  },
  computed: {
    optionsMapped () {
      return this.options.map(option => {
        return {
          id: option.id,
          name: option[`name_${this.$store.getters.language.lang}`],
          value: option.id,
          icon_code: option.icon_code
        }
      })
    },
    optionsSelected () {
      return this.optionsMapped.filter(item => {
        return this.values.some(value => value.id === item.id)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.chips
  display: flex
  flex-wrap: wrap
</style>
