<template>
  <ModalContainer
    @on-nav-back="close"
    @right-action-clicked="$emit('right-click')"
    :rightAction="{ icon: 'icon-check-single' }"
    :title="$translations.profile['therapy-style'].sections['populations-title']">
    <template v-slot:content>
      <div class="heading-small margin-bottom" v-text="$translations.profile['therapy-style'].sections['populations-subtitle']" />
      <div class="chips">
        <Chip
          v-for="(item, index) in localValues"
          :key="index"
          size="small"
          color="purple"
          :text="item.name"
        />
      </div>

      <div class="heading-small margin-y" v-text="$translations.profile['therapy-style'].sections['populations-title']" />
      <MultiselectField
        v-if="options.length"
        :items-selected="optionsSelected"
        :options="optionsMapped"
        @value="addValues($event)"
      />
    </template>
  </ModalContainer>
</template>

<script>
import { MultiselectField, Chip } from '@seliaco/red-panda'
import ModalContainer from '@/components/modals/ModalContainer'

export default {
  name: 'PopulationFormModal',
  components: {
    ModalContainer,
    MultiselectField,
    Chip
  },
  props: {
    values: [],
    options: []
  },
  mounted () {
    this.localValues = this.values.map(option => {
      return {
        id: option.id,
        name: option.name,
        value: option.id
      }
    })
  },
  data () {
    return {
      loading: false,
      localValues: null,
      newValues: null
    }
  },
  methods: {
    addValues (values) {
      this.newValues = values
      /*
      * The local values are read only
      */
      this.localValues = this.newValues.map(option => {
        return {
          id: option.id,
          name: option.name || option.text,
          value: option.id
        }
      })
    },
    close () {
      this.$emit('close', this.newValues)
    }
  },
  computed: {
    optionsMapped () {
      return this.options.map(option => {
        return {
          id: option.id,
          name: option[`name_${this.$store.getters.language.lang}`],
          value: option.id
        }
      })
    },
    optionsSelected () {
      return this.optionsMapped.filter(item => {
        return this.values.some(value => value.id === item.id)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.chips
  margin-top: 10px
  display: flex
  gap: 4px
  flex-wrap: wrap
  ::v-deep .body-small
    overflow: unset
    text-overflow: unset
</style>
